import { Component, OnInit} from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';

@Component({
  selector: 'apartments-overview',
  templateUrl: './apartments-overview.component.html',
})

export class ApartmentsOverviewComponent implements OnInit {

  data:any = null;
  loaded:any = null;

  constructor(private _http: Http) {}

  ngOnInit(){
    this.getWPData();
  }

  private getWPData() {

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/266')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.data = data;
        this.loaded = true;
      });
  }
}
