import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class MessageService {
  private _listners = new Subject<any>();
  private _listners1 = new Subject<any>();
  private _listners2 = new Subject<any>();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  listenFunction(): Observable<any> {
    return this._listners1.asObservable();
  }

  callFunction(data: number) {
    this._listners1.next(data);
  }

  listenPreload(): Observable<any> {
    return this._listners2.asObservable();
  }

  callPreload() {
    this._listners2.next();
  }
}
