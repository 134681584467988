import { Component, OnInit } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import * as $ from 'jquery';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import 'gsap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {

  dataForm:any = null;
  loadedForm:any = null;
  message:any = null;

  $module: any = $('app-footer');

  constructor(private _http: Http, router:Router) {
  }

  ngOnInit() {}
}
