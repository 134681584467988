import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { SwiperComponent } from 'angular2-useful-swiper';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../shared/services/message.service';
import * as $ from 'jquery';

@Component({
  selector: 'swiper-default',
  templateUrl: './swiper-default.component.html'
})

export class SwiperDefaultComponent implements OnInit{

  data: any = null;
  loaded: any = null;
  private currentRoute:String = null;
  private swiper:any = null;

  constructor(private _http: Http, private _messageService: MessageService) {}

  @ViewChild('usefulSwiper') usefulSwiper: SwiperComponent;

  config: SwiperOptions = {
    nextButton: '.swiper-button-next--default',
    prevButton: '.swiper-button-prev--default',
    loop: true,
    autoHeight: true,
    spaceBetween: 30,
    threshold: 20,
    slidesPerView: 1
  };

  ngOnInit(){

    var self = this;

    this._messageService.listen().subscribe((data:any) => {
      self.data = data;
      self.loaded = true;
    });

    this._messageService.listenFunction().subscribe((index:any) => {
      self.getFullscreen(index,'detail');
    });

    $(window).resize(function(){
      self.resizer();
    });

    setTimeout(function(){
      self.usefulSwiper.swiper.update();
      self.usefulSwiper.swiper.slideNext(false,0);
      self.usefulSwiper.swiper.slidePrev(false,0);
    }, 1000);
  }

  private getFullscreen (event,_type) {

    if(typeof event == 'number') {

      $('.m-swiper-detail').addClass('show');
      $('.m-fullscreen').addClass('is-fullscreen');
      $('body').addClass('is-fullscreen');

      this.resizer();
      this.usefulSwiper.swiper.update();

      this.usefulSwiper.swiper.slideTo(event+1,0);
    } else {

      $('.m-swiper-detail').removeClass('show');
      $('.m-swiper-default').toggleClass('detail');
      $('.m-fullscreen').toggleClass('is-fullscreen');
      $('body').toggleClass('is-fullscreen');

      this.resizer();
      this.usefulSwiper.swiper.slideNext(false,0);
      this.usefulSwiper.swiper.slidePrev(false,0);
    }
  }

  private resizer () {

    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    if($('.m-swiper-default').hasClass('detail')) {
      $('.m-swiper-default').find('img').css('max-width',windowWidth+'px');
      $('.m-swiper-default').find('img').css('max-height',windowHeight+'px');
      $('.m-swiper-default .swiper-slide').css('width',windowWidth+'px');
      $('.m-swiper-default .swiper-slide').css('height',windowHeight+'px');
    } else {
      $('.m-swiper-default').find('img').css('max-width','100%');
      $('.m-swiper-default').find('img').css('max-height','100%');
      $('.m-swiper-default .swiper-slide').css('width','100%');
      $('.m-swiper-default .swiper-slide').css('height','auto');
    }

    $('.m-swiper-detail').find('img').css('max-width',windowWidth+'px');
    $('.m-swiper-detail').find('img').css('max-height',windowHeight+'px');
    $('.m-swiper-detail .swiper-slide').css('width',windowWidth+'px');
    $('.m-swiper-detail .swiper-slide').css('height',windowHeight+'px');

    this.usefulSwiper.swiper.update();
  }
}
