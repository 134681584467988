import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { MessageService } from '../../../shared/services/message.service';
import { Ng2DeviceService } from 'ng2-device-detector';
import * as $ from 'jquery';
import { TweenMax, Sine } from "gsap";

@Component({
  selector: 'swiper-home',
  templateUrl: './swiper-home.component.html'
})

export class SwiperHomeComponent implements OnInit {

  data: any = null;
  loaded: any = null;
  swiperHeight: any = null;
  images: any = [];
  deviceIPhone = null;
  windowWidth: any = null;
  windowHeight: any = null;
  scrollPos: number = 0;

  config: SwiperOptions = {
    nextButton: '.swiper-button-next--home',
    prevButton: '.swiper-button-prev--home',
    loop: false,
    threshold: 20,
    autoplay: 8000
  };

  constructor(private _http: Http, private _messageService: MessageService, private deviceService: Ng2DeviceService ) {}

  ngOnInit() {

    var self = this;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this._messageService.listen().subscribe((data:any) => {
      self.data = data;
      self.loaded = true;
    });

    this.deviceIPhone = this.deviceService.getDeviceInfo().device;

    this.resizer('init');
    $(window).resize(function(){
      self.resizer('resize');
    });
  }

  private scrollDown() {
    this.scrollPos = $('.mod-home').offset().top - 90;
    TweenMax.to($('html,body'), 0.5, {scrollTop:this.scrollPos, ease:Sine.easeInOut});
  }

  private resizer (_type) {

    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    if(_type == 'init') {
      this.swiperHeight = {
        //height:  this.windowHeight+'px'
      }
    }

    if(_type == 'resize' && this.deviceIPhone !== 'iphone' ) {

      this.swiperHeight = {
        //height:  this.windowHeight+'px'
      }
    }
  }
}
