import { Component, state } from '@angular/core';
import {trigger, animate, style, group, animateChild, query, stagger, transition} from '@angular/animations';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { MessageService } from './shared/services/message.service';
import * as $ from 'jquery';

const animationTime = '0.5s';
const animationEase = 'ease-in-out';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('routerAnimation', [
    /**
     * page animation
     */
      transition('* => *', [
        query(':enter, :leave', style({ position: 'fixed', width:'100%', height:'100%' }), { optional: true }),
        group([
          query(':enter', [
            style({ opacity:0, transform: 'rotateX(0deg) scale(1)', transformOrigin: 'center center' }),
            animate(animationTime + ' ' + animationEase, style({ opacity:1, transform: 'rotateX(0deg) scale(1)', transformOrigin: 'center center' }))
          ], { optional: true }),
          query(':leave', [
            style({ opacity:1, transform: 'scale(1)', transformOrigin: 'center center' }),
            animate(animationTime + ' ' + animationEase, style({ opacity:0, transform: 'translate(0,10%) scale(1)', transformOrigin: 'center center' }))
          ], { optional: true }),
        ])
      ])
    ])
  ]
})

export class AppComponent {

  state: string = 'small';
  private preloadTime:any = 1000;

  constructor(router:Router, private _messageService: MessageService) {

    /**
     * show preloader on state change
     */
    setTimeout(function(){
      $('.js-preloader').addClass('hide');
    }, this.preloadTime);

    router.events.forEach((event: NavigationEvent) => {

      $('.js-preloader').removeClass('hide');
      $('.js-footer').css('display', 'none');

      setTimeout(function(){
        $('.js-preloader').addClass('hide');
        $('.js-footer').css('display', 'block');
      }, this.preloadTime);
    });

    //this._messageService.listenPreload().subscribe(() => {
    //  $('.js-preloader').addClass('hide');
    //  $('.js-footer').css('display', 'block');
    //});
  }

  /**
   * animation state
   * @param outlet
   * @returns {any}
     */
  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation
  }
}
