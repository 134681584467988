import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';

@Component({
  selector: 'legal',
  templateUrl: './legal.component.html'
})

export class LegalComponent implements OnInit {

  data: any = null;
  loaded: any = null;
  private currentRoute:String = null;

  constructor(private _http: Http, private route:ActivatedRoute) {
    this.currentRoute = route.snapshot.routeConfig.path;
  }

  ngOnInit() {
    this.getWPData();
  }

  private getWPData() {

    var id:any = null;

    if(this.currentRoute == 'impressum') {
      id = 315;
    } else if(this.currentRoute == 'datenschutz') {
      id = 317;
    }

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/'+id)
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.data = data;
        this.loaded = true;
      });
  }
}
