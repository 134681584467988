import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as Headroom from 'headroom.js';
import { TweenMax, Back } from "gsap";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {

  $module: any = $('app-header');

  constructor() {}

  ngOnInit() {

    const classSelector = document.querySelector('.lay-header');
    const headroom = new Headroom(classSelector, { offset: 50 });
    headroom.init();


    $('.js-nav-mobile .js-expand').each(function() {

      var $panel = $(this).next();
      $panel.css('max-height', '0');

      $(this).on('click', function() {

        $(this).toggleClass('active');
        $panel.toggleClass('active');

        if (!$panel.hasClass('active')){
          $panel.css('max-height', '0')
        } else {
          $panel.css('max-height', $panel[0].scrollHeight+'px')
        }
      });
    });
  }

  toggleNav() {

    this.$module.find('.js-burger, .js-burger-icon, .js-nav-mobile').toggleClass('open');

    if(this.$module.find('.js-nav-mobile').hasClass('open')) {

      this.$module.find('.js-nav-mobile > ul > li').each(function(i){
        TweenMax.fromTo($(this),0.5,{alpha:0, y:-50},{alpha:1, y:0, delay:0.2+i*0.1, ease:Back.easeOut})
      });
    } else {

      this.$module.find('.js-nav-mobile > ul > li').each(function(i){
        TweenMax.fromTo($(this),0.5,{alpha:1, y:0},{alpha:0, y:-50, delay:i*0.1, ease:Back.easeIn})
      });
    }
  }


}
