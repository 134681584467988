/**
 * import modules
 */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule }    from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SwiperModule } from 'angular2-useful-swiper';
import { NguiStickyModule } from '@ngui/sticky';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import 'swiper';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import * as $ from 'jquery';

/**
 * import components
 */
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ApartmentsOverviewComponent } from './pages/apartments-overview/apartments-overview.component';
import { ApartmentsDetailComponent } from './pages/apartments-detail/apartments-detail.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { HistoryComponent } from './pages/history/history.component';
import { ValuationsComponent } from './pages/valuations/valuations.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LegalComponent } from './pages/legal/legal.component';
import { SwiperHomeComponent } from './shared/components/swiper/swiper-home.component';
import { SwiperDefaultComponent } from './shared/components/swiper/swiper-default.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';


/**
 * import services
 */
import {MessageService} from './shared/services/message.service';
import {DomSanitizerService} from './shared/services/domsanitizer.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ApartmentsOverviewComponent,
    ApartmentsDetailComponent,
    CalendarComponent,
    HistoryComponent,
    ValuationsComponent,
    ContactComponent,
    LegalComponent,
    HeaderComponent,
    FooterComponent,
    SwiperHomeComponent,
    SwiperDefaultComponent,
    DomSanitizerService
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'hipe-angular'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    SwiperModule,
    NguiStickyModule,
    Ng2DeviceDetectorModule.forRoot()
  ],
  providers: [
    MessageService,
    DomSanitizerService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor() {

    $('body').addClass('show');
  }
}
