import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html'
})

export class ContactComponent implements OnInit {

  private data: any = null;
  private  loaded: any = null;

  constructor(private _http: Http) {}

  ngOnInit() {
    this.getWPData();
  }


  private getWPData() {

  }
}
