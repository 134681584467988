import { Component, OnInit } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import 'rxjs/add/operator/map';
import * as $ from 'jquery';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html'
})

export class CalendarComponent implements OnInit {

  data: any = null;
  loaded: any = null;
  private currentRoute:String = null;

  constructor(private _http: Http, private route:ActivatedRoute, public sanitizer: DomSanitizer) {
    this.currentRoute = route.snapshot.routeConfig.path;
  }

  ngOnInit(){
    this.getWPData();
  }

  private getWPData() {

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/11')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.data = data;
        this.loaded = true;

        setTimeout(function(){
          $('.js-calendar .js-load-text').addClass('hide');
          $('.js-calendar iframe').addClass('show');
        }, 3000);
      });
  }
}
