import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';

@Component({
  selector: 'valuations',
  templateUrl: './valuations.component.html'
})

export class ValuationsComponent implements OnInit {

  data: any = null;
  loaded: any = null;
  private currentRoute:String = null;

  constructor(private _http: Http, private route:ActivatedRoute) {
    this.currentRoute = route.snapshot.routeConfig.path;
  }

  ngOnInit() {
    this.getWPData();
  }

  private getWPData() {

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/341')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.data = data;
        this.loaded = true;
      });
  }
}
