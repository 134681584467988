import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { MessageService } from '../../shared/services/message.service';
import 'rxjs/add/operator/map';

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {

  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  data: any = null;
  loaded: any = null;
  state: string = 'small';

  constructor(private _http: Http, private _messageService: MessageService ) {}

  ngOnInit() {
    this.getWPData();
  }

  private getSwiper(_index) {
    this._messageService.callFunction(_index);
  }

  private getWPData() {

    return this._http.get('https://haussylta.de/wp-json/acf/v3/pages/13')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.data = data;
        this.loaded = true;
        this._messageService.filter(data);
      });
  }
}
